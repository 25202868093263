import React, { useState, useEffect } from 'react';
import { db } from '../../auth/firebase';
import { collection, getDocs, query, where, doc, updateDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { getAuth, sendPasswordResetEmail, deleteUser } from 'firebase/auth';
import './assessment-builder.css';
import Header from '../Header';
import PillButton from '../../ui/PillButton';
import Loader from '../../ui/Loader';

const SupportPage = () => {
    const [loading, setLoading] = useState(true);
    const [preUsers, setPreUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editUsers, setEditUsers] = useState([]);
    const [isEditingCouple, setIsEditingCouple] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteConfirmName, setDeleteConfirmName] = useState('');
    const [userToDelete, setUserToDelete] = useState(null);
    const [authUsers, setAuthUsers] = useState([]);

    useEffect(() => {
        const loadAllData = async () => {
            await fetchData();
            await fetchAuthUsers();
        };
        loadAllData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            
            // Fetch preUsers
            const preUsersSnapshot = await getDocs(collection(db, 'preUsers'));
            const preUsersData = preUsersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                type: 'preUser'
            }));

            // Fetch users
            const usersSnapshot = await getDocs(collection(db, 'users'));
            const usersData = usersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                type: 'user'
            }));

            setPreUsers(preUsersData);
            setUsers(usersData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch data');
            setLoading(false);
        }
    };

    const fetchAuthUsers = async () => {
        try {
            const response = await fetch('https://us-central1-vallotton-app.cloudfunctions.net/listAuthUsers', {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error('Failed to fetch authentication users');
            }

            const data = await response.json();
            setAuthUsers(data.users);
        } catch (error) {
            console.error('Error fetching auth users:', error);
            setError('Failed to fetch authentication users');
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredPreUsers = preUsers.filter(user => 
        user.email?.toLowerCase().includes(searchTerm) ||
        user.firstName?.toLowerCase().includes(searchTerm) ||
        user.coupleCode?.toLowerCase().includes(searchTerm)
    );

    const filteredUsers = users.filter(user => 
        user.email?.toLowerCase().includes(searchTerm) ||
        user.firstName?.toLowerCase().includes(searchTerm) ||
        user.coupleCode?.toLowerCase().includes(searchTerm)
    );

    const filteredAuthUsers = authUsers.filter(user => 
        user.email?.toLowerCase().includes(searchTerm)
    );

    const handleDeletePreUser = (preUser) => {
        setUserToDelete(preUser);
        setShowDeleteModal(true);
    };

    const handleResendInvite = async (preUser) => {
        try {
            setLoading(true);
            const response = await fetch('https://us-central1-vallotton-app.cloudfunctions.net/resendInviteEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: preUser.email.toLowerCase()
                })
            });

            if (response.ok) {
                setSuccessMessage('Invitation email resent successfully');
            } else {
                throw new Error('Failed to resend invitation');
            }
        } catch (error) {
            console.error('Error resending invite:', error);
            setError('Failed to resend invitation');
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = async (user) => {
        try {
            setLoading(true);
            setError(null);
            let usersToEdit = [];
            const isPreUser = user.type === 'preUser';

            if (user.coupleCode) {
                // Find all users with the same couple code from the appropriate collection
                const coupleUsers = (isPreUser ? preUsers : users).filter(u => 
                    u.coupleCode === user.coupleCode
                );
                if (coupleUsers.length > 1) {
                    usersToEdit = coupleUsers;
                    setIsEditingCouple(true);
                } else {
                    usersToEdit = [user];
                    setIsEditingCouple(false);
                }
            } else {
                usersToEdit = [user];
                setIsEditingCouple(false);
            }

            setEditUsers(usersToEdit);
            setShowEditModal(true);
        } catch (error) {
            console.error('Error preparing edit:', error);
            setError('Failed to prepare edit form');
        } finally {
            setLoading(false);
        }
    };

    const truncateToken = (token) => {
        if (!token) return '';
        if (token.length <= 12) return token;
        return `${token.substring(0, 6)}...${token.substring(token.length - 6)}`;
    };

    const handleEditSubmit = async (updatedUser) => {
        try {
            setLoading(true);
            setError(null);

            const isPreUser = updatedUser.type === 'preUser';
            const collectionName = isPreUser ? 'preUsers' : 'users';
            const userRef = doc(db, collectionName, updatedUser.id);

            const updateData = {
                firstName: updatedUser.firstName,
                email: updatedUser.email.toLowerCase(),
                coupleCode: updatedUser.coupleCode,
            };

            // Only include accessToken for preUsers
            if (isPreUser) {
                updateData.accessToken = updatedUser.accessToken;
            } else {
                // Only include isAdmin for regular users
                updateData.isAdmin = updatedUser.isAdmin || false;
            }

            await updateDoc(userRef, updateData);

            setSuccessMessage('User updated successfully');
            await fetchData(); // Refresh the data
        } catch (error) {
            console.error('Error updating user:', error);
            setError('Failed to update user');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteUser = (user) => {
        setUserToDelete(user);
        setShowDeleteModal(true);
    };

    const handleResetPassword = async (userEmail) => {
        try {
            setLoading(true);
            const auth = getAuth();
            await sendPasswordResetEmail(auth, userEmail.toLowerCase());
            setSuccessMessage('Password reset email sent successfully');
        } catch (error) {
            console.error('Error sending password reset:', error);
            setError('Failed to send password reset email');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteAuthUser = async (authUser) => {
        setUserToDelete({ ...authUser, type: 'authUser', firstName: authUser.email.split('@')[0] });
        setShowDeleteModal(true);
    };

    const handleDeleteConfirmation = async () => {
        if (!userToDelete) return;

        if (deleteConfirmName.trim() !== userToDelete.firstName) {
            setError('Name does not match. Please try again.');
            return;
        }

        try {
            setLoading(true);
            setError(null);

            if (userToDelete.type === 'preUser') {
                await deleteDoc(doc(db, 'preUsers', userToDelete.id));
                setSuccessMessage('Pre-user deleted successfully');
            } else if (userToDelete.type === 'authUser') {
                const response = await fetch('https://us-central1-vallotton-app.cloudfunctions.net/deleteAuthUser', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email: userToDelete.email.toLowerCase()
                    })
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.details || 'Failed to delete authentication user');
                }

                setSuccessMessage('Authentication user deleted successfully');
                await fetchAuthUsers();
            } else {
                // Delete user and all associated data
                const batch = writeBatch(db);
                const subcollections = ['progresses', 'userResponses', 'results'];
                
                for (const subcollection of subcollections) {
                    const subcollectionRef = collection(db, `users/${userToDelete.id}/${subcollection}`);
                    const subcollectionDocs = await getDocs(subcollectionRef);
                    subcollectionDocs.forEach(doc => {
                        batch.delete(doc.ref);
                    });
                }

                batch.delete(doc(db, 'users', userToDelete.id));
                await batch.commit();

                try {
                    const response = await fetch('https://us-central1-vallotton-app.cloudfunctions.net/deleteAuthUser', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: userToDelete.email.toLowerCase()
                        })
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        console.error('Error deleting auth user:', errorData);
                        throw new Error(errorData.details || 'Failed to delete authentication user');
                    }
                } catch (authError) {
                    console.error('Error deleting auth user:', authError);
                    setError('User data deleted but failed to delete authentication user. Please try again or contact support.');
                }

                setSuccessMessage('User and all associated data deleted successfully');
            }

            await fetchData();
            setShowDeleteModal(false);
            setDeleteConfirmName('');
            setUserToDelete(null);
        } catch (error) {
            console.error('Error deleting user:', error);
            setError(`Failed to delete ${userToDelete.type === 'preUser' ? 'pre-user' : userToDelete.type === 'authUser' ? 'authentication user' : 'user'}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Header />
            <div className="builder-container">
                <h1>Support Page</h1>
                
                {error && <div className="error-message">{error}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}

                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Search by email, name, or couple code"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <div className="support-content">
                        <div className="section">
                            <h2 className="section-title">
                                <span className="highlight">PRE-USERS:</span>
                                <span className="secondary"> Placeholders prior to First Login</span>
                            </h2>
                            <div className="user-list">
                                {filteredPreUsers.map(preUser => (
                                    <div key={preUser.id} className="user-card">
                                        <div className="user-info">
                                            <p><strong>Name:</strong> {preUser.firstName}</p>
                                            <p><strong>Email:</strong> {preUser.email}</p>
                                            <p>
                                                <strong>Couple Code:</strong><br />
                                                {preUser.coupleCode}
                                            </p>
                                            <p>
                                                <strong>Access Token:</strong><br />
                                                {truncateToken(preUser.accessToken)}
                                            </p>
                                        </div>
                                        <div className="user-actions">
                                            <button
                                                className="text-button"
                                                onClick={() => handleEdit(preUser)}
                                            >
                                                Edit
                                            </button>
                                            <span className="separator">|</span>
                                            <button
                                                className="text-button"
                                                onClick={() => handleResendInvite(preUser)}
                                            >
                                                Resend Email
                                            </button>
                                            <span className="separator">|</span>
                                            <button
                                                className="text-button"
                                                onClick={() => handleDeletePreUser(preUser)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="section users-section">
                            <h2 className="section-title">
                                <span className="highlight">USERS:</span> Logged in with Assessment Access
                            </h2>
                            <div className="user-list">
                                {filteredUsers.map(user => (
                                    <div key={user.id} className="user-card">
                                        <div className="user-info">
                                            <p><strong>Name:</strong> {user.firstName}</p>
                                            <p><strong>Email:</strong> {user.email}</p>
                                            <p>
                                                <strong>Couple Code:</strong><br />
                                                {user.coupleCode}
                                            </p>
                                            <p><strong>Authorized:</strong> {user.authorized ? 'Yes' : 'No'}</p>
                                            <p><strong>Admin:</strong> {user.isAdmin ? 'Yes' : 'No'}</p>
                                        </div>
                                        <div className="user-actions">
                                            <button
                                                className="text-button"
                                                onClick={() => handleEdit(user)}
                                            >
                                                Edit
                                            </button>
                                            <span className="separator">|</span>
                                            <button
                                                className="text-button"
                                                onClick={() => handleResetPassword(user.email)}
                                            >
                                                Reset Password
                                            </button>
                                            <span className="separator">|</span>
                                            <button
                                                className="text-button"
                                                onClick={() => handleDeleteUser(user)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="section auth-users-section">
                            <h2 className="section-title">
                                <span className="highlight">AUTH USERS:</span> Firebase Authentication Users
                            </h2>
                            <div className="user-list">
                                {filteredAuthUsers.map(authUser => (
                                    <div key={authUser.uid} className="user-card">
                                        <div className="user-info">
                                            <p><strong>Email:</strong> {authUser.email}</p>
                                            <p><strong>UID:</strong> {authUser.uid}</p>
                                            <p><strong>Email Verified:</strong> {authUser.emailVerified ? 'Yes' : 'No'}</p>
                                            <p><strong>Account Status:</strong> {authUser.disabled ? 'Disabled' : 'Active'}</p>
                                            <p><strong>Last Sign In:</strong> {new Date(authUser.lastSignInTime).toLocaleString()}</p>
                                            <p><strong>Created:</strong> {new Date(authUser.creationTime).toLocaleString()}</p>
                                        </div>
                                        <div className="user-actions">
                                            <button
                                                className="text-button"
                                                onClick={() => handleResetPassword(authUser.email)}
                                            >
                                                Reset Password
                                            </button>
                                            <span className="separator">|</span>
                                            <button
                                                className="text-button"
                                                onClick={() => handleDeleteAuthUser(authUser)}
                                            >
                                                Delete Auth
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {showEditModal && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>{isEditingCouple ? 'Edit Couple' : 'Edit User'}</h2>
                            {editUsers.map((user, index) => (
                                <div key={user.id} className="edit-user-section">
                                    {isEditingCouple && (
                                        <h3>Partner {index + 1}</h3>
                                    )}
                                    <div className="edit-field">
                                        <label>Name:</label>
                                        <input
                                            type="text"
                                            value={user.firstName}
                                            onChange={(e) => {
                                                const updatedUsers = [...editUsers];
                                                updatedUsers[index] = {
                                                    ...user,
                                                    firstName: e.target.value
                                                };
                                                setEditUsers(updatedUsers);
                                            }}
                                        />
                                    </div>
                                    <div className="edit-field">
                                        <label>Email:</label>
                                        <input
                                            type="email"
                                            value={user.email}
                                            onChange={(e) => {
                                                const updatedUsers = [...editUsers];
                                                updatedUsers[index] = {
                                                    ...user,
                                                    email: e.target.value.toLowerCase()
                                                };
                                                setEditUsers(updatedUsers);
                                            }}
                                        />
                                    </div>
                                    <div className="edit-field">
                                        <label>Couple Code:</label>
                                        <input
                                            type="text"
                                            value={user.coupleCode}
                                            onChange={(e) => {
                                                const updatedUsers = [...editUsers];
                                                updatedUsers[index] = {
                                                    ...user,
                                                    coupleCode: e.target.value
                                                };
                                                setEditUsers(updatedUsers);
                                            }}
                                        />
                                    </div>
                                    <div className="edit-field">
                                        <label>Access Token:</label>
                                        <input
                                            type="text"
                                            value={user.accessToken}
                                            onChange={(e) => {
                                                const updatedUsers = [...editUsers];
                                                updatedUsers[index] = {
                                                    ...user,
                                                    accessToken: e.target.value
                                                };
                                                setEditUsers(updatedUsers);
                                            }}
                                        />
                                    </div>
                                    {user.type === 'user' && (
                                        <div className="edit-field">
                                            <label>Admin Access:</label>
                                            <div className="toggle-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={user.isAdmin === true}
                                                    onChange={() => {
                                                        const updatedUsers = [...editUsers];
                                                        updatedUsers[index] = {
                                                            ...user,
                                                            isAdmin: !user.isAdmin
                                                        };
                                                        setEditUsers(updatedUsers);
                                                    }}
                                                />
                                                <span className="toggle-slider"></span>
                                            </div>
                                            <span className="toggle-label">{user.isAdmin ? 'Yes' : 'No'}</span>
                                        </div>
                                    )}
                                    <div className="user-save-button">
                                        <button
                                            className="text-button"
                                            onClick={() => handleEditSubmit(user)}
                                        >
                                            Save Changes
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div className="modal-actions">
                                <button
                                    className="text-button"
                                    onClick={() => setShowEditModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showDeleteModal && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Confirm Deletion</h2>
                            <p>You are about to delete {userToDelete?.type === 'preUser' ? 'pre-user' : userToDelete?.type === 'authUser' ? 'authentication user' : 'user'}:</p>
                            <p><strong>{userToDelete?.firstName}</strong></p>
                            {userToDelete?.type === 'user' && (
                                <p className="warning-text">This will permanently delete all their data, including assessment responses and results.</p>
                            )}
                            <p>To confirm, please type the name <strong>{userToDelete?.firstName}</strong> below:</p>
                            <div className="edit-field">
                                <input
                                    type="text"
                                    value={deleteConfirmName}
                                    onChange={(e) => setDeleteConfirmName(e.target.value)}
                                    placeholder="Type name to confirm"
                                />
                            </div>
                            <div className="modal-actions">
                                <button
                                    className="text-button"
                                    onClick={() => {
                                        setShowDeleteModal(false);
                                        setDeleteConfirmName('');
                                        setUserToDelete(null);
                                        setError(null);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="text-button delete-confirm-button"
                                    onClick={handleDeleteConfirmation}
                                    disabled={deleteConfirmName !== userToDelete?.firstName}
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SupportPage; 