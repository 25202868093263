import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';
import Header from './Header';
import Loader from '../ui/Loader';
import { db } from '../auth/firebase';
import { doc, getDoc, collection, getDocs, query, where, updateDoc, arrayUnion } from 'firebase/firestore';
import AssessmentProcessor from './AssessmentProcessor';
import { initializeAssessmentProgresses } from '../services/AssessmentService';
import './home-page.css';
import CongratulationsPage from './CongratulationsPage';
import PillButton from '../ui/PillButton';

const HomePage = () => {
  const { currentUser, userData, loading, logout } = useConsolidatedUser();
  const navigate = useNavigate();
  const [logoutError, setLogoutError] = useState(null);
  const [assessments, setAssessments] = useState([]);
  const [progresses, setProgresses] = useState([]);
  const [allAssessmentsDone, setAllAssessmentsDone] = useState(false);
  const [congratulations, setCongratulations] = useState(false);
  const [hasUserResult, setHasUserResult] = useState(false);
  const [, setHasPartnerResult] = useState(false);
  const [, setSpouseName] = useState("");
  const [errorProcessingResult, setErrorProcessingResult] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  {/* 
  As an extroverted and strong leader, I'm intentional in uplifting those around me, 
  always guided by a gentle and compassionate heart. My greatest desire is to reflect 
  the overflowing love of Jesus by loving and caring for others as He would. I'm driven
  by a deep yearning to bring Heaven to earth so that His beauty and grace can be seen by all.  
  */ };


  {/*

    1. CHECK LOCAL BOOLEAN (allAssessmentsDone) FOR ASSESSMENT COMPLETION
    2. IF BOOLEAN FALSE
         a. Check user progresses to confirm
         a. If user's progresses don't exist, create them.
         b. If they aren't complete, show assessment list.
         c. If they are complete, set boolean true
     3. IF BOOLEAN TRUE
         a. If no user report, create one and show CONGRATS div
         b. Share view report button.

  */}


  //---------------------------------
  //
  //           STEP ONE
  //      
  //      CHECK COMPLETION
  //
  //---------------------------------


  useEffect(() => {


    if (!currentUser) {
      setLoadingData(false);
      return;
    }

    if (hasUserResult && !allAssessmentsDone) {
      setLoadingData(false);
      setAllAssessmentsDone(true)
      return
    }

    // Avoid firing if results already exist, assessments are already done, or if there was an error
    if ((allAssessmentsDone && hasUserResult) || errorProcessingResult) {
      setLoadingData(false);
      return;
    }

    console.log('HP ----> 1. CHECK LOCAL BOOLEAN (allAssessmentsDone) FOR ASSESSMENT COMPLETION');


    const fetchAndCheckProgresses = async () => {
      if (!allAssessmentsDone) {
        setLoadingData(true);
        console.log('HP ----> 2a. IF BOOLEAN FALSE check USER PROGRESSES:');
        await fetchProgresses();
        console.log('HP ----> 2b. PROGRESSES FETCHED, CHECKING RESULTS');
        checkUserResults();
      }
    };

    const checkUserResults = async () => {

      if (allAssessmentsDone && !hasUserResult && !errorProcessingResult) {
        console.log('HP ----> 3. ALL ASSESSMENTS DONE');

        try {
          console.log('HP ----> 4. CHECKING FOR USER RESULTS');

          const resultsRef = collection(db, 'users', currentUser.uid, 'results');
          const resultsSnapshot = await getDocs(resultsRef);
          const noUserResults = resultsSnapshot.empty;

          if (noUserResults) {
            console.log('HP ----> 5. NO USER RESULTS. CREATING THEM.');

            const processor = new AssessmentProcessor('Marriage Connection Assessment');
            const processResult = await processor.processAssessments();

            console.log('HP ----> 5. RESULTS CREATED.');

            if (processResult === 'success') {
              console.log('HP ----> 6. USER RESULTS CREATED - CONGRATULATIONS ON COMPLETION!');
              setCongratulations(true);
              setHasUserResult(true);
              setErrorProcessingResult(false);
              setAllAssessmentsDone(true);
              setLoadingData(false);
            } else {
              console.log('HP ----> 6. XXXXXX FAILED - COULD NOT GENERATE RESULTS!');
              setCongratulations(false);
              setHasUserResult(false);
              setErrorProcessingResult(true);
              setLoadingData(false);
            }
          } else {
            setErrorProcessingResult(false);
            setCongratulations(false);
            setHasUserResult(true);
            setLoadingData(false);
          }
        } catch (error) {
          console.error('Error checking results or processing assessments:', error);
          setLoadingData(false);
        }
      } else {
        setLoadingData(false);
        console.log('HP ----> CHECK THESE');
        console.log('HP ---->    allAssmentsDone: ', allAssessmentsDone);
        console.log('HP ---->    hasUserResult: ', hasUserResult);
        console.log('HP ---->    errorProcessingResult: ', errorProcessingResult);
      }
    };

    fetchAndCheckProgresses();

    // Dependencies to ensure it only runs when necessary
  }, [currentUser, allAssessmentsDone, hasUserResult, errorProcessingResult]);


  const fetchProgresses = async () => {
    if (progresses.length === 0) {
      console.log('HP ------- Progresses Not Yet Fetched.');
      try {
        const progressesRef = collection(db, `users/${currentUser.uid}/progresses`);
        const progressesSnapshot = await getDocs(progressesRef);

        if (progressesSnapshot.empty) {
          console.log('HP ------- NO PROGRESSES FOUND -- CREATING THEM.');
          await initializeAssessmentProgresses(currentUser);

          // After initializing, fetch the progresses again
          const newProgressesSnapshot = await getDocs(progressesRef);
          const fetchedProgresses = newProgressesSnapshot.docs.map((doc) => doc.data());

          // Use the fetched progresses directly here instead of waiting for the state to update
          console.log('HP ------- PROGRESSES FETCHED AFTER INITIALIZATION:', fetchedProgresses);
          setProgresses(fetchedProgresses);

          // Use fetchedProgresses directly here to compute `allDone`
          const allDone = fetchedProgresses.every((progress) => progress.status === 3);
          setAllAssessmentsDone(allDone);
          console.log('HP ------- USING FETCHED PROGRESSES TO SET allAssessmentsDone = ', allDone);
        } else {
          const fetchedProgresses = progressesSnapshot.docs.map((doc) => doc.data());

          // Use the fetched progresses directly here instead of waiting for the state to update
          console.log('HP ------- PROGRESSES FETCHED:', fetchedProgresses);
          setProgresses(fetchedProgresses);

          // Use fetchedProgresses directly here to compute `allDone`
          const allDone = fetchedProgresses.every((progress) => progress.status === 3);
          setAllAssessmentsDone(allDone);
          console.log('HP ------- USING FETCHED PROGRESSES TO SET allAssessmentsDone = ', allDone);
        }
      } catch (error) {
        console.error('HP xxxxxxx ERROR FETCHING PROGRESSES:', error);
      }
    } else {
      console.log('HP ------- Progresses already Fetched.');
    }
  };


  //---------------------------------
  //      
  //       HELPER FUNCTIONS
  //
  //---------------------------------

  const handleNavigateToAssessment = (progress) => {
    if (progress.status > 0 && progress.status < 3) {
      navigate(`/take-assessment/${progress['url-title']}`);
    }
  };

  const handleClose = () => {
    setCongratulations(false);
  };

  //---------------------------------
  //      
  //       UI RETURN BLOCK
  //
  //---------------------------------

  return (
    <div>
      <Header />
      {console.log('Popup condition values:', {
        allAssessmentsDone,
        congratulations,
        mcaCongratulations: userData?.mcaCongratulations
      })}

      {(allAssessmentsDone && congratulations && !userData?.mcaCongratulations) && (
        <CongratulationsPage
          currentUser={currentUser}
          onClose={handleClose}
        />
      )}

      <main className="home-main">

        {console.log("++++++++++++++ Loading States:", { loading })}

        {(loading || loadingData) && <Loader />}

        {/* Show logout error if any */}
        {logoutError && <p style={{ color: 'red' }}>{logoutError}</p>}

        {/* Welcome message */}
        <h2>Hello, {userData?.firstName || 'there'}!</h2>

        {console.log("++++++++++++++ AT EDGE OF !allAssessmentsDone")}

        {/* Show assessments when they are not all done */}
        {!allAssessmentsDone ? (
          <>
            {console.log("============= INSIDE THE !allAssessmentsDone Block! Progresses Count = ", progresses.length)}
            <div className="assessment-container-outer">
              <div className="assessment-container-inner">
                {progresses.length > 0 ? (
                  progresses
                    .sort((a, b) => a.index - b.index)  // Sort directly by progress.index
                    .map((progress) => (
                      <div
                        key={progress['url-title']}
                        onClick={() => handleNavigateToAssessment(progress)}  // Navigate using progress information
                        className={`assessment-row ${progress.status === 0 || progress.status === 3 ? 'disabled' : ''}`}
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/${progress.status !== 3 ? 'assessment-icon-bg' : 'assessment-icon-done-bg'}.png)`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          borderRadius: '8px',
                          padding: '1rem',
                          marginBottom: '20px',
                          transition: 'background-image 0.3s ease',
                          maxWidth: '800px',
                          width: '100%',
                          boxSizing: 'border-box',
                          '@media (max-width: 768px)': {
                            width: '80%'
                          }
                        }}
                        onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundImage = `url(${process.env.PUBLIC_URL}/assets/${progress.status !== 3
                          ? 'assessment-icon-bg-hover'
                          : 'assessment-icon-bg-done-hover'}.png)`)
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundImage = `url(${process.env.PUBLIC_URL}/assets/${progress.status !== 3 ? 'assessment-icon-bg' : 'assessment-icon-done-bg'}.png)`)
                        }
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/assessment-${progress.status}.png`}
                          alt={`${progress.title || 'Assessment'} icon`}
                          style={{ height: '64px', marginRight: '1rem' }}
                        />
                        <h3>{progress.title}</h3>
                      </div>
                    ))
                ) : (
                  <p>No assessments available.</p>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="assessment-container-outer">
            <div className="done-container-inner">
              <p>🎉  Well done! You've taken an important step towards strengthening your marriage.
                By completing the Marriage Connection Assessment, you've demonstrated your commitment to understanding and improving your relationship. This assessment is not just a test—it's a launching pad for growth, deeper connection, and a more fulfilling partnership.</p>
              <p>We're excited for the journey ahead of you. As you apply the insights from this assessment, may you experience deeper love, improved communication, and a thriving partnership that brings joy to both of you.</p>
              {/* Add other content here for the "else" case */}
              <PillButton
                theme="dark"
                className="wide"
                type="button"
                onClick={() => navigate('/results')} // Navigate to /results on click
              >
                View Results
                <span className="triangle"></span>
              </PillButton>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default HomePage;