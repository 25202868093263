import React, { useState } from 'react';
import { auth } from '../auth/firebase';
import EmailLottie from '../ui/EmailLottie';
import './email-confirmation.css';
import PillButtonNoOutline from '../ui/PillButtonNoOutline';
import Header from './Header';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';

const EmailConfirmation = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);

  // Get currentUser and handleLogout from the ConsolidatedUserContext
  const { currentUser, userData, logout } = useConsolidatedUser();

  const handleLogout = async () => {
    try {
      await logout(); // Execute logout
    } catch (err) {
      console.error('Logout failed:', err);
    }
  };

  const resendVerificationEmail = async () => {
    try {
      setError(null); // Reset any previous error messages

      if (auth.currentUser) {
        // Log the normalized email for consistency in logs
        console.log('Sending verification email to:', auth.currentUser.email.toLowerCase());
        
        // Send the verification email using Firebase Auth
        await auth.currentUser.sendEmailVerification();
        setEmailSent(true); // Update state to show success message
      } else {
        setError('No user is currently signed in.');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      setError('Failed to send verification email. Please try again later.');
    }
  };

  return (
    <div>
      {/* Add the necessary props for Header */}
      <Header />
      <div className="email-confirmation-container">
        {/* Lottie animation */}
        <EmailLottie />

        {/* Text content */}
        <div className="email-confirmation-box">
          <h2>Check Your Email</h2>
          <p>We've sent invitation links to you and your spouse. Please check your inbox <i>and your junk mail</i>.</p>

          {/* Show a success message if the email was sent */}
          {emailSent && <p className="success-message">Verification email resent! Please check your inbox.</p>}

          {/* Show an error message if something went wrong */}
          {error && <p className="error-message">{error}</p>}

          {/* Button to resend the verification email */}
          <PillButtonNoOutline
            size="small"
            theme="dark"
            width="narrow"
            onClick={resendVerificationEmail}
          >
            {'Resend'}
            <span className="triangle"></span>
          </PillButtonNoOutline>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;