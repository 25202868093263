import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import UserProfileSetup from './components/UserProfileSetup';
import LoginForm from './components/login/LoginForm';
import RequestAccess from './components/login/RequestAccess';
import ErrorPage from './components/ErrorPage';
import AssessmentBuilder from './components/builder/AssessmentBuilder';
import SupportPage from './components/builder/SupportPage';

import TakeAssessment from './components/TakeAssessment';
import AssessmentResults from './components/AssessmentResults';
import LandingPage from './components/LandingPage';
import Authorize from './components/login/Authorize';
import HomePage from './components/HomePage'
import EmailConfirmation from './components/EmailConfirmation';
import ProfilePage from './components/ProfilePage';
import InvitePage from './components/InvitePage';
import ContactPage from './components/ContactPage';
import { useConsolidatedUser } from './contexts/ConsolidatedUserContext';
import Loader from './ui/Loader';
import PrivacyPage from './components/PrivacyPage';


// ErrorBoundary component to catch errors
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>{this.state.error.toString()}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

const ProtectedRoute = ({ children }) => {
  const { currentUser, loading } = useConsolidatedUser();
  
  if (loading) {
    return <Loader />;
  }
  
  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

const AdminRoute = ({ children }) => {
  const { currentUser, userData, loading } = useConsolidatedUser();
  
  if (loading) {
    return <Loader />;
  }
  
  if (!currentUser || !userData?.isAdmin) {
    return <Navigate to="/home-page" replace />;
  }
  
  return children;
};



// AppContent: simplified without user state
const AppContent = () => {
  return (
    <ErrorBoundary>
      <Routes>
        {/* Ensure root path directly renders LandingPage */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact-page" element={<ContactPage />} />
        <Route path="/request-access" element={<RequestAccess />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/authorize" element={<Authorize />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/email-confirmation" element={<EmailConfirmation />} />
        <Route path="/error" element={<ErrorPage />} />

        {/* Logged in users */}
        <Route path="/home-page" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
        <Route path="/take-assessment/:assessmentUrlTitle" element={<ProtectedRoute><TakeAssessment /></ProtectedRoute>} />
        <Route path="/results" element={<ProtectedRoute><AssessmentResults /></ProtectedRoute>} />
        <Route path="/profile"  element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />


        {/* Admin */}
        <Route path="/edit" element={<AdminRoute><AssessmentBuilder /></AdminRoute>} />
        <Route path="/invite" element={<AdminRoute><InvitePage /></AdminRoute>} />
        <Route path="/support" element={<AdminRoute><SupportPage /></AdminRoute>} />

        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ErrorBoundary>
  );
};

// Simplified App without context or user checks
const App = () => {
  return (
    <ErrorBoundary>
      <AppContent />
    </ErrorBoundary>
  );
};

export default App;


{/* 
  TODO:

  1. - EMAIL VERIFICATION 
  When launching EmailConfirmation, pass in string to print to screen.
  And how to handle resend button?
  
  2. - SHOW ALERT IN HOME PAGE IF RESULTS NOT GENERATED (line 104-ish)
  
  
  */}