import React, { useState, useEffect } from 'react';
import { useConsolidatedUser } from '../../contexts/ConsolidatedUserContext';
import { useNavigate } from 'react-router-dom';
import './login-form.css';
import '../../index.css';
import logo from '../../assets/marriage-connection-logo.png';
import PillButton from '../../ui/PillButton';
import Loader from '../../ui/Loader';
import { db } from '../../auth/firebase';
import { collection, getDocs, query, where, doc, setDoc } from 'firebase/firestore';
import BackButton from '../../ui/BackButton';
import { linkSpouses } from '../../services/LinkSpouses';

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return 'This email is already in use. Please try logging in instead.';
    case 'auth/invalid-email':
      return 'Please enter a valid email address.';
    case 'auth/weak-password':
      return 'Your password is too weak. Please choose a stronger password.';
    case 'auth/wrong-password':
      return 'Incorrect password. Please try again.';
    case 'auth/user-not-found':
      return 'No account found with this email. Please sign up first.';
    default:
      return 'An unexpected error occurred. Please try again.';
  }
};

const Authorize = () => {
  const { signUp, error: contextError, setError, currentUser } = useConsolidatedUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [token, setToken] = useState(''); // Token from URL
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  // Extract token from URL when the component mounts
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    setToken(tokenFromUrl);
  }, []);

  // If the user is already logged in, navigate to the home page
  useEffect(() => {
    if (currentUser) {
      navigate('/home-page');
    }
  }, [currentUser, navigate]);

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage(null);
    setIsSubmitting(true);

    // Normalize email to lowercase
    const emailLower = email.toLowerCase();

    if (!isValidEmail(emailLower)) {
      setError('Please enter a valid email address.');
      setIsSubmitting(false);
      return;
    }

    if (!token) {
      setError('Missing token. Please use the correct link.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Step 1: Get all preUsers with matching token
      const preUserQuery = query(
        collection(db, 'preUsers'),
        where('accessToken', '==', token)
      );
      const preUserSnapshot = await getDocs(preUserQuery);

      // Step 2: Find matching email case-insensitively
      const matchingDoc = preUserSnapshot.docs.find(doc => 
        doc.data().email.toLowerCase() === emailLower
      );

      if (!matchingDoc) {
        throw new Error('Invalid email or token.');
      }

      // Retrieve the preUser data
      const preUser = matchingDoc.data();
      const { firstName, coupleCode } = preUser;

      // Step 2: Create the new user in Firebase Auth
      const userCredential = await signUp(emailLower, password);
      const user = userCredential.user;

      // Step 3: Store the user data in Firestore under 'users' collection
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        firstName,
        email: emailLower,
        coupleCode,
        authorized: true,
      });

      // Step 4: Call the linkSpouses function to link the logged-in user with their spouse
      await linkSpouses(user);

      // Step 5: Navigate to the home page after successful sign up
      navigate('/home-page');

    } catch (err) {
      console.error('Error during authorization or validation:', err);
      const errorMessage = err.code ? getErrorMessage(err.code) : err.message;
      setError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <div><BackButton /></div>
      <div className="login-form-container">
        {isSubmitting && <Loader />} {/* Show Loader when submitting */}
        {!isSubmitting && (
          <>
            <div className="logo-container">
              <img src={logo} alt="Marriage Connection Logo" style={{ width: '300px', height: 'auto' }} />
            </div>
            <form onSubmit={handleSubmit} className="login-form">
              <h2 className="auth-title">Authorize Your Account</h2>
              {contextError && <p className="error-message">{contextError}</p>}
              {message && <p style={{ color: 'green' }}>{message}</p>}
              <input
                type="email"
                placeholder="Email"
                value={email.toLowerCase()}
                onChange={(e) => {
                  setEmail(e.target.value.toLowerCase());
                  setError(null); // Clear error when user types
                }}
                required
              />
              <input
                type="password"
                placeholder="Create a Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(null); // Clear error when user types
                }}
                required
              />

              <PillButton theme="light" className="wide" type="submit" disabled={isSubmitting}>
                Authorize Account
                <span className="triangle"></span>
              </PillButton>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Authorize;