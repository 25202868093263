import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, updateDoc, addDoc, getDocs } from 'firebase/firestore';
import { db } from '../auth/firebase';
import { useConsolidatedUser } from '../contexts/ConsolidatedUserContext';
import Header from './Header';
import './take-assessment.css';
import '../index.css';
import Loader from '../ui/Loader';
import ProgressBar from '../ui/ProgressBar';

const TakeAssessment = () => {
    const { currentUser, userData } = useConsolidatedUser();
    const { assessmentUrlTitle } = useParams();
    const [assessment, setAssessment] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [progressesDoc, setProgressesDoc] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(null);
    const [visibleIndex, setVisibleIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAnimating, setIsAnimating] = useState(false);
    const [totalQuestions, setTotalQuestions] = useState(0);
    const [setHeaderHeight] = useState(0);
    const headerRef = useRef(null);
    const [userResponses, setUserResponses] = useState([]); // Store full response objects
    const navigate = useNavigate();

    // Function to fetch the next question based on the visible index
    const fetchNextQuestion = async (assessmentData, index) => {
        try {
            console.log('=======> Fetching Question at index ', index);

            const questionRef = assessmentData.questions[index];
            const questionSnapshot = await getDoc(questionRef);

            if (questionSnapshot.exists()) {
                const questionData = questionSnapshot.data();
                setCurrentQuestion(questionData);
                console.log("Q - ", questionData.isNegative);
            } else {
                console.error('No question found for the current index');
            }
        } catch (error) {
            console.error('Error fetching question:', error);
        }
    };

    // Function to fetch user responses
    useEffect(() => {
        const fetchAssessment = async () => {
            try {
                if (!currentUser || !userData || !assessmentUrlTitle) {
                    console.error("Missing required data: currentUser, userData, or assessmentId");
                    setLoading(false);
                    return;
                }
    
                const assessmentRef = doc(db, 'assessments', assessmentUrlTitle);
                const progressesRef = collection(db, `users/${currentUser.uid}/progresses`);
                const progressQuery = query(progressesRef, where('url-title', '==', assessmentUrlTitle));
                const progressSnapshot = await getDocs(progressQuery);
    
                if (progressSnapshot.empty) {
                    console.error("No progress document available for the current assessment");
                    setLoading(false);
                    return;
                }
    
                const foundProgressDoc = progressSnapshot.docs[0];
                setProgressesDoc(foundProgressDoc);
                const progressData = foundProgressDoc.data();
                console.log('Progress Data:', progressData);

                const assessmentSnapshot = await getDoc(assessmentRef);
                if (assessmentSnapshot.exists()) {
                    const assessmentData = assessmentSnapshot.data();
                    setAssessment(assessmentData);
                    setTotalQuestions(assessmentData.questions.length);

                    const initialIndex = progressData['question-index'] || 0;
                    console.log('Initial Index:', initialIndex);
                    setQuestionIndex(initialIndex);
                    setVisibleIndex(initialIndex);

                    await fetchNextQuestion(assessmentData, initialIndex);
                    setLoading(false);
                } else {
                    console.error('Assessment not found');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching assessment or progress document:', error);
                setLoading(false);
            }
        };
    
        if (currentUser && userData && assessmentUrlTitle) {
            // Only fetch if we have necessary data
            fetchAssessment();
        }
    }, [currentUser, userData, assessmentUrlTitle]); // Dependencies: only re-run if these change

    // Function to navigate backward
    const handlePreviousQuestion = () => {
        if (visibleIndex > 0) {
            setVisibleIndex(prevVisibleIndex => {
                const newIndex = prevVisibleIndex - 1;
                console.log('============== FETCH NEXT AT ', newIndex, ' ==============');
                applyRecordedAnswerStyle(newIndex);
                fetchNextQuestion(assessment, newIndex);
                return newIndex;
            });
        }
    };

    // Function to navigate forward
    const handleNextQuestion = () => {
        if (visibleIndex < questionIndex) {
            setVisibleIndex(prevVisibleIndex => {
                const newIndex = prevVisibleIndex + 1;
                console.log('============== FETCH NEXT AT ', newIndex, ' ==============');
                applyRecordedAnswerStyle(newIndex);
                fetchNextQuestion(assessment, newIndex);
                return newIndex;
            });
        }
    };

    // Handle answer selection
    const handleAnswerSelection = async (answerIndex) => {
        try {
            if (!currentUser || !progressesDoc || questionIndex === null) {
                console.error("User, progresses document, or question index is missing");
                return;
            }

            setIsAnimating(true);

            const progressesData = progressesDoc.data();
            const isNegative = currentQuestion.isNegative || false;
            let selectedAnswer = answerIndex + 1;
            if (isNegative) {
                selectedAnswer = currentQuestion.answers.length - selectedAnswer + 1;
            }

            console.log('============== CREATE RESPONSE FOR ', visibleIndex, ' ==============');

            const userResponse = {
                assessment: doc(db, `assessments/${progressesData['url-title']}`),
                questionIndex: visibleIndex,
                user: doc(db, `users/${currentUser.uid}`),
                answerIndex: selectedAnswer,
                calcId: currentQuestion['calc-id'],
                isNegative: currentQuestion['isNegative'],
                timestamp: new Date(),
            };

            const userResponseRef = collection(db, `users/${currentUser.uid}/userResponses`);

            // Query for an existing response that matches the visibleIndex
            const querySnapshot = await getDocs(query(userResponseRef,
                where('assessment', '==', userResponse.assessment),
                where('questionIndex', '==', visibleIndex)
            ));

            console.log("New response for ", visibleIndex, ": ", userResponse);

            if (!querySnapshot.empty) {
                const existingDoc = querySnapshot.docs[0];
                await updateDoc(existingDoc.ref, userResponse);
            } else {
                await addDoc(userResponseRef, userResponse);
            }

            // Update local state with the new or updated response
            setUserResponses(prevResponses => {
                const existingResponseIndex = prevResponses.findIndex(res => res.questionIndex === visibleIndex);
                if (existingResponseIndex > -1) {
                    // Update the existing response in the local array
                    const updatedResponses = [...prevResponses];
                    updatedResponses[existingResponseIndex] = userResponse;
                    return updatedResponses;
                } else {
                    // Add new response to the local array
                    return [...prevResponses, userResponse];
                }
            });

            await updateDoc(doc(db, `users/${currentUser.uid}/progresses`, progressesDoc.id), {
                'question-index': questionIndex,
                status: questionIndex >= assessment.questions.length ? 3 : 2,
            });

            const newIndex = visibleIndex + 1;
            console.log('Checking for END STATE:', newIndex, ' of ', assessment.questions.length);

            // Check if it's the last question
            if (newIndex >= assessment.questions.length) {
                console.log('BOOM - END STATE!');

                // Set the current progress doc to final index and status 3 (done)
                await updateDoc(doc(db, `users/${currentUser.uid}/progresses`, progressesDoc.id), {
                    'question-index': newIndex,
                    status: 3, // Mark as done
                });

                // Get the current index from the progress doc and increment it
                const currentIndex = progressesDoc.data().index;
                const nextIndex = currentIndex + 1;

                // Query the subcollection for a progress doc with the incremented index
                const progressesCollectionRef = collection(db, `users/${currentUser.uid}/progresses`);
                const nextProgressQuery = query(progressesCollectionRef, where('index', '==', nextIndex));
                const nextProgressSnapshot = await getDocs(nextProgressQuery);

                if (!nextProgressSnapshot.empty) {
                    const nextProgressDoc = nextProgressSnapshot.docs[0]; // Get the first matching progress doc
                    const nextProgressDocRef = nextProgressDoc.ref;

                    // Unlock the next assessment if its status is 0 (locked)
                    if (nextProgressDoc.exists() && nextProgressDoc.data().status === 0) {
                        await updateDoc(nextProgressDocRef, {
                            status: 1, // Unlock the next assessment
                        });
                        console.log('Unlocked the next assessment');
                    }

                    // Navigate back to the home page
                    navigate('/home-page');
                } else {
                    // No more assessments, navigate to /congratulations
                    // ADD COMPLETION TO RESULT
                    setLoading(true)
                    try {
                        const userRef = doc(db, 'users', currentUser.uid); 
                        await updateDoc(userRef, {
                          mcaCongratulations: true
                        });
                  
                        console.log('Congratulations field updated on user.');
                        setLoading(false)
                      } catch (error) {
                        setLoading(false)
                        console.error('Error updating user object:', error);
                      }
                    console.log('No more assessments to unlock, navigating to home');
                    navigate('/home-page');
                }
            } else {
                // Fetch and set the next question
                setVisibleIndex(newIndex);
                if (newIndex > questionIndex) {
                    setQuestionIndex(newIndex)
                }
    
                applyRecordedAnswerStyle(newIndex);
    
                console.log('============== FETCH NEXT AT ', newIndex, ' ==============');
                await fetchNextQuestion(assessment, newIndex);
            }

            setIsAnimating(false);

        } catch (error) {
            console.error('Error saving user response or updating progress:', error);
        }
    };

    const applyRecordedAnswerStyle = (newVisibleIndex) => {
        const recordedAnswer = userResponses.find(res => res.questionIndex === newVisibleIndex);

        if (recordedAnswer) {
            console.log("Recorded answer for index", newVisibleIndex, ":", recordedAnswer);
        }
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <Header isAssessmentPage={true} />
            <ProgressBar
                currentIndex={visibleIndex} // Use visibleIndex for progress
                totalQuestions={totalQuestions}
            />

            <div className="take-assessment-container">
                <div className="take-assessment-content">
                    {currentQuestion ? (
                        <>
                            <div className="index-label" style={{ fontSize: '12px', color: '#666', textAlign: 'center' }}>
                                {/*{visibleIndex} / {totalQuestions - 1} -- {userResponses.length}*/}
                            </div>
                            <h2 className={`question-text ${isAnimating ? 'faded' : ''}`}>
                                {currentQuestion.text}
                            </h2>
                            <div className={`answers-container ${isAnimating ? 'faded' : ''}`}>
                                {currentQuestion.answers.slice().reverse().map((answer, index) => {
                                    const buttonIndex = currentQuestion.answers.length - 1 - index;

                                    // Find the recorded answer for the current question based on visibleIndex
                                    const recordedAnswer = userResponses.find(res => res.questionIndex === visibleIndex); // Search for the correct response

                                    // Invert the button index if the question is marked as "isNegative"
                                    const invertedIndex = currentQuestion.isNegative
                                        ? currentQuestion.answers.length - buttonIndex
                                        : buttonIndex + 1; // Normal index

                                    // Check if this answer button matches the previously selected answer
                                    const isRecordedAnswer = recordedAnswer && recordedAnswer.answerIndex === invertedIndex;

                                    return (
                                        <button
                                            key={index}
                                            onClick={() => handleAnswerSelection(buttonIndex)}
                                            className={`answer-button ${isAnimating ? 'faded' : ''} ${isRecordedAnswer ? 'recorded-answer' : ''}`}
                                            disabled={isAnimating}
                                            style={isRecordedAnswer ? { color: 'var(--color_9)' } : {}}
                                        >
                                            {answer}
                                        </button>
                                    );
                                })}
                            </div>
                        </>
                    ) : (
                        <div>No answers available for this question</div>
                    )}
                </div>

                {/* Left Arrow */}
                {visibleIndex > 0 && (
                    <img
                        src="/assets/leftArrow.png"
                        onMouseOver={e => (e.currentTarget.src = '/assets/leftArrow-hover.png')}
                        onMouseOut={e => (e.currentTarget.src = '/assets/leftArrow.png')}
                        className="left-arrow"
                        alt="Previous Question"
                        onClick={handlePreviousQuestion}
                    />
                )}

                {/* Right Arrow */}
                {visibleIndex < questionIndex && (
                    <img
                        src="/assets/rightArrow.png"
                        onMouseOver={e => (e.currentTarget.src = '/assets/rightArrow-hover.png')}
                        onMouseOut={e => (e.currentTarget.src = '/assets/rightArrow.png')}
                        className="right-arrow"
                        alt="Next Question"
                        onClick={handleNextQuestion}
                    />
                )}
            </div>
        </div>
    );
};

export default TakeAssessment;